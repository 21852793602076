import React from 'react'
import { Link } from 'gatsby'

import Layout from 'gatsby-theme-stack/src/layout'
import Image from '../components/image'
import SEO from '../components/seo'

import Pricing from 'gatsby-theme-stack/src/pricing'
import Hero from 'gatsby-theme-stack/src/hero'
//import Feature from '../fusion/feature'
import About from 'gatsby-theme-stack/src/feature'
import Product from 'gatsby-theme-stack/src/product'
import Service from 'gatsby-theme-stack/src/service'
import VideoPromo from 'gatsby-theme-stack/src/video-promo'
import Team from 'gatsby-theme-stack/src/team'
import Counter from 'gatsby-theme-stack/src/counter'
import Skill from 'gatsby-theme-stack/src/skill'
import Portfolio from 'gatsby-theme-stack/src/portfolio'
import Testimony from 'gatsby-theme-stack/src/testimony'
import Blog from 'gatsby-theme-stack/src/blog'
import Client from 'gatsby-theme-stack/src/client'
import Contact from 'gatsby-theme-stack/src/contact'

import { AuthService, useAuth } from 'gatsby-theme-auth0'

import { translate } from 'react-i18next'

const IndexPage = () => {
  const { isLoading, isLoggedIn, profile } = useAuth()

  const myClick = function () {
    alert('hallo')
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Hero></Hero>
      <Product></Product>
      { /* <Feature></Feature> */ }
      <Service></Service>
      <Counter></Counter>
      <VideoPromo></VideoPromo>
      <About></About>
      <Team></Team>
      <Skill></Skill>
      <Client></Client>
      <Portfolio></Portfolio>
      <Testimony></Testimony>
      <Pricing></Pricing>
      <Blog></Blog>
      <Contact></Contact>
    </Layout>
  )
}
export default IndexPage
