import React from 'react'

import PropTypes from 'prop-types'

import Testimony from './Testimony'

class TestimonyList extends React.PureComponent {
  render() {
    const { nodes } = this.props

    return (
      <section id="testimonial" className="testimonial section-padding">
        <div className="overlay"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div id="testimonials" className="owl-carousel wow fadeInUp" data-wow-delay="1.2s">
                { nodes.map((e, i) => <Testimony key={`etestimony_${i}`} node={e}></Testimony>) }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

TestimonyList.propTypes = {
  nodes: PropTypes.any
}

export default TestimonyList
