import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ClientList from './components/ClientList'

const Client = () => {
  const data = useStaticQuery(graphql`
    query StackClientQuery {
      allClientJson {
        edges {
          node {
            name
            description
            image
            url
          }
        }
      }
    }
  `)

  return (
    <ClientList nodes={data.allClientJson.edges.map(e => e.node)}></ClientList>
  )
}

export default Client
