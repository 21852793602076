import React from 'react'

import PropTypes from 'prop-types'
import Skill from './Skill'

class SkillList extends React.PureComponent {
  render() {
    const { nodes } = this.props

    return (
      <div className="skill-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s">
              <img className="img-fluid" src="/stack/img/about/img-1.jpg" alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-xs-12 info wow fadeInRight" data-wow-delay="0.3s">
              <div className="site-heading">
                <h2 className="section-title">Our <span>Skill</span></h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus architecto laudantium dolorem, aut aspernatur modi minima alias provident obcaecati! Minima odio porro nemo magnam dolore minus asperiores veniam dolorum est!
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas, nesciunt possimus quaerat ipsam, corporis architecto aspernatur non aut! Dolorum consectetur placeat excepturi, perspiciatis sunt.
                </p>
              </div>
              <div className="skills-section">
                {nodes.map((e, i) => <Skill key={`eskill_${i}`} node={e}></Skill>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SkillList.propTypes = {
  nodes: PropTypes.any
}

export default SkillList
