import React from 'react'

import PropTypes from 'prop-types'

class Testimony extends React.PureComponent {
  componentDidMount() {
    /*

    var owl = $("#testimonials");
      owl.owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        center: true,
        margin: 15,
        slideSpeed: 1000,
        stopOnHover: true,
        autoPlay: true,
        responsiveClass: true,
        responsiveRefreshRate: true,
        responsive : {
            0 : {
                items: 1
            },
            768 : {
                items: 1
            },
            960 : {
                items: 1
            },
            1200 : {
                items: 1
            },
            1920 : {
                items: 1
            }
        }
      });

*/
  }

  render() {
    const { node } = this.props

    return <div key={'test_' + node.name.replace(' ', '')} className="item">
      <div className="testimonial-item">
        <div className="img-thumb">
          <img src={node.image} alt="" />
        </div>
        <div className="info">
          <h2><a href="#">{node.name}</a></h2>
          <h3><a href="#">{node.position}, {node.organization}</a></h3>
        </div>
        <div className="content">
          <p className="description">{node.content}</p>
        </div>
      </div>
    </div>
  }
}

Testimony.propTypes = {
  node: PropTypes.any
}

export default Testimony
