import React from 'react'

import PropTypes from 'prop-types'

class Team extends React.PureComponent {
  render() {
    const { node } = this.props

    return <div key={'team_' + node.name.replace(' ', '')} className="team-item text-center wow fadeInRight" data-wow-delay="0.3s">
      <div className="team-img">
        <img className="img-fluid" src={node.image} alt={node.name} />
        <div className="team-overlay">
          <div className="overlay-social-icon text-center">
            <ul className="social-icons">
              <li><a><i className="lni-facebook-filled" aria-hidden="true"></i></a></li>
              <li><a><i className="lni-twitter-filled" aria-hidden="true"></i></a></li>
              <li><a><i className="lni-instagram-filled" aria-hidden="true"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="info-text">
        <h3><a>{node.name}</a></h3>
        <p>{node.position}</p>
      </div>
    </div>
  }
}

Team.propTypes = {
  node: PropTypes.any
}

export default Team
