import React from 'react'
import PropTypes from 'prop-types'

class Blog extends React.PureComponent {
  render() {
    const { node } = this.props

    return <div key={'blog_' + node.title.replace(' ', '')} className="col-lg-4 col-md-6 col-sm-12 col-xs-12 blog-item">
      <div className="blog-item-wrapper wow fadeInLeft" data-wow-delay="0.3s">
        <div className="blog-item-img">
          <a href="single-post.html">
            <img src={node.image} alt="" />
          </a>
        </div>
        <div className="blog-item-text">
          <h3>
            <a href="single-post.html">{node.title}</a>
          </h3>
          <p>
            {node.content}
          </p>
          <a href="single-post.html" className="btn btn-common btn-rm">Read More</a>
        </div>
      </div>
    </div>
  }
}

Blog.propTypes = {
  node: PropTypes.any
}

export default Blog
