import React from 'react'

import PropTypes from 'prop-types'

class Pricing extends React.PureComponent {
  render() {
    const { node } = this.props

    return (
      <div key={'pricing_' + node.name} className="col-lg-4 col-md-6 col-xs-12">
        <div className="table wow fadeInLeft" data-wow-delay="1.2s">
          <div className="title">
            <h3>{node.name}</h3>
          </div>
          <div className="pricing-header">
            <p className="price-value">${node.price}<span>/ Month</span></p>
          </div>
          <ul className="description">
            { node.items.map(item => {
              return (<li key={node.name + item.name}>item.name</li>)
            }) }
          </ul>
          <button className="btn btn-common">Subscribe</button>
        </div>
      </div>)
  }
}

Pricing.propTypes = {
  node: PropTypes.any
}

export default Pricing
