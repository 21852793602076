import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Hero from './components/Hero'

export default () => {
  const data = useStaticQuery(graphql`
  query StackHeroQuery {
    allHeroJson {
      edges {
        node {
          title
          summary
          action {
            title
            url
          }
        }
      }
    }}`)

  return (data.allHeroJson.edges.map(({ node }, i) => <Hero key={'ehero_' + i} node={node}></Hero>))
}
