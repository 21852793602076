import React from 'react'

import PropTypes from 'prop-types'

class Feature extends React.PureComponent {
  render() {
    const { node } = this.props

    return (
      <div key={'feature_' + node.title.replace(' ', '')} className="feature-item wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">
        <div className="icon">
          <i className="lni-microphone"></i>
        </div>
        <div className="feature-content">
          <h3>What we do</h3>
          <p>We develop system to automate your personal and professional life</p>
        </div>
      </div>
    )
  }
}

Feature.propTypes = {
  node: PropTypes.any
}

export default Feature
