import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import FeatureList from './components/FeatureList'

const Feature = () => {
  const data = useStaticQuery(graphql`
  query StackAboutQuery {
    allAboutJson {
      edges {
        node {
          title
        }
      }
    }
  }
`)

  return (
    <FeatureList nodes={data.allAboutJson.edges.map(e => e.node)} />
  )
}

export default Feature
