import React from 'react';
import { useStaticQuery, graphql } from "gatsby";

import TeamList from './components/TeamList';

const Team = () => {

  const data = useStaticQuery(graphql`
  query StackTeamQuery {
    allTeamJson {
      edges {
        node {
          name
          position
          image
          delay
          social {
            twitter
            facebook
            instagram
          }
        }
      }
    }
  }
`)

  return (
    <TeamList nodes={ data.allTeamJson.edges.map(e => e.node) }></TeamList>
)
  }

export default Team