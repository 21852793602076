import React from 'react'

import PropTypes from 'prop-types'

class Hero extends React.PureComponent {
  render() {
    const { node } = this.props

    return (
      <div key={'hero_' + node.title.replace(' ', '')} id="hero-area" className="hero-area-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="contents text-center">
                <h2 className="head-title wow fadeInUp">{node.title}</h2>
                <div className="header-button wow fadeInUp" data-wow-delay="0.3s">
                  <a href="#services" className="btn btn-common">{node.action.title}</a>
                </div>
              </div>
              <p className="mb-4">no credit card required</p>
              <div className="img-thumb text-center wow fadeInUp" data-wow-delay="0.6s">
                <img className="img-fluid" src="/stack/img/hero-1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Hero.propTypes = {
  node: PropTypes.any
}

export default Hero
