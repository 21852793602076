import React from 'react';
import { useStaticQuery, graphql } from "gatsby";

import ProductList from './components/ProductList';

const Product = () => {
  const data = useStaticQuery(graphql`
  query StackProductQuery {
    allProductJson {
      edges {
        node {
          name
          description
          visible
          icon
          image
          actions {
            title
            url
          }
        }
      }
    }
  }
`)

  return (
    <ProductList nodes={ data.allProductJson.edges.map(e => e.node)}></ProductList>
 )
}

export default Product