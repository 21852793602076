import React from 'react'
import PropTypes from 'prop-types'

class Contact extends React.PureComponent {
  render() {
    return (<section id="contact" className="section-padding">
      <div className="container">
        <div className="row contact-form-area wow fadeInUp" data-wow-delay="0.4s">
          <div className="col-md-6 col-lg-6 col-sm-12">
            <div className="contact-block">
              <form id="contactForm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" className="form-control" id="name" name="name" placeholder="Name" required data-error="Please enter your name"></input>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" placeholder="Email" id="email" className="form-control" name="email" required data-error="Please enter your email"></input>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input type="text" placeholder="Subject" id="msg_subject" className="form-control" required data-error="Please enter your subject"></input>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea className="form-control" id="message" placeholder="Your Message" rows="5" data-error="Write your message" required></textarea>
                      <div className="help-block with-errors"></div>
                    </div>
                    <div className="submit-button">
                      <button className="btn btn-common" id="form-submit" type="submit">Send Message</button>
                      <div id="msgSubmit" className="h3 text-center hidden"></div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-sm-12">
            <div className="contact-right-area wow fadeIn">
              <div className="contact-title">
                <h1>We&apos;re a friendly bunch..</h1>
                <p>We create projects for companies and startups with a passion for quality</p>
              </div>
              <h2>Contact Us</h2>
              <div className="contact-right">
                <div className="single-contact">
                  <div className="contact-icon">
                    <i className="lni-map-marker"></i>
                  </div>
                  <p>ADDRESS: 28 Green Tower, New York City, USA</p>
                </div>
                <div className="single-contact">
                  <div className="contact-icon">
                    <i className="lni-envelope"></i>
                  </div>
                  <p><a href="mail:support@sengsara.com">Email:  contact@stuck.com</a></p>
                </div>
                <div className="single-contact">
                  <div className="contact-icon">
                    <i className="lni-phone-handset"></i>
                  </div>
                  <p><a href="tel:123213">Phone:  +84 846 250 592</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>)
  }
}

Contact.propTypes = {
  node: PropTypes.any
}

export default Contact
