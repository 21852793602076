import React from 'react'

import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import $ from 'jquery'

import '../styles//nivo-lightbox.css'

class Portfolio extends React.PureComponent {
  componentDidMount() {
    // const isSSR = typeof window === 'undefined'
    // import('../../../static/stack/js/nivo-lightbox.js')
  }

  render() {
    var { node } = this.props

    return (
      <div key={'portfolio_' + node.name} className={'col-lg-4 col-md-6 col-xs-12 mix ' + node.category}>
        <div className="portfolio-item">
          <div className="shot-item">
            <img src={node.image} alt="" />
            <div className="single-content">
              <div className="fancy-table">
                <div className="table-cell">
                  <div className="zoom-icon">
                    <a className="lightbox" href={node.image}><i className="lni-eye item-icon"></i></a>
                  </div>
                  <a>{node.name}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
  }
}

Portfolio.propTypes = {
  node: PropTypes.any
}

export default Portfolio
