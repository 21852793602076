import React from 'react'

import PropTypes from 'prop-types'

class Skill extends React.PureComponent {
  render() {
    const { node } = this.props

    return <div key={'skill_' + node.name.replace(' ', '')} className="progress-box">
      <h5>{node.name} <span className="pull-right">{node.value}%</span></h5>
      <div className="progress" style={{ opacity: 1, left: '0px' }}>
        <div className="progress-bar" role="progressbar" data-width={node.value} style={{ width: node.value + '%' }}></div>
      </div>
    </div>
  }
}

Skill.propTypes = {
  node: PropTypes.any
}

export default Skill
