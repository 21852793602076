import React from 'react'

import PropTypes from 'prop-types'

import Feature from './Feature'

class FeatureList extends React.PureComponent {
  render() {
    const { nodes } = this.props

    return (

      <div id="about">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="text-wrapper">
                <div>
                  <h2 className="title-hl wow fadeInLeft" data-wow-delay="0.3s">We are helping to grow <br /> your business.</h2>
                  <p className="mb-4">A digital studio specialising in User Experience & eCommerce, we combine innovation with digital craftsmanship to help brands fulfill their potential.</p>
                  <a href="/about" className="btn btn-common">More About Us</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 padding-none feature-bg">
              <div className="feature-thumb">
                {nodes.map((e, i) => <Feature key={'efeature_' + i} node={e}></Feature>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FeatureList.propTypes = {
  nodes: PropTypes.any
}

export default FeatureList
