import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BlogList from './components/BlogList'

const Blog = () => {
  const data = useStaticQuery(graphql`
  query StackBlogQuery {
    allBlogJson {
      edges {
        node {
          title
          content
          url
        }
      }
    }}`)

  return (
    <BlogList nodes={data.allBlogJson.edges.map(e => e.node)}></BlogList>
  )
}

export default Blog
