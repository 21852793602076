import React from 'react'
import PropTypes from 'prop-types'

import Product from './Product'

class ProductList extends React.PureComponent {
  render() {
    const { nodes } = this.props

    return (
      <section id="products" className="section-padding bg-gray">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Our Products</h2>
            <p>A desire to help and empower others between community contributors in technology <br /> began to grow in 2020.</p>
          </div>
          <div className="row">
            {nodes.map((e, i) => <Product key={'eprod_' + i} node={e}></Product>)}
          </div>
        </div>
      </section>
    )
  }
}

ProductList.propTypes = {
  nodes: PropTypes.any
}

export default ProductList
