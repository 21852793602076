import React from 'react';
import { useStaticQuery, graphql } from "gatsby";

import ServiceList from './components/ServiceList';

const Service = () => {
  const data = useStaticQuery(graphql`
  query StackServiceQuery {
    allServiceJson {
      edges {
        node {
          title
          content
          visible
          icon
          action {
            title
            url
          }
        }
      }
    }
  }
`)


  return (
    <ServiceList nodes={ data.allServiceJson.edges.map(e => { return e.node })}></ServiceList>
 )
}

export default Service