import React from 'react';
import { useStaticQuery, graphql } from "gatsby";

import SkillList from './components/SkillList';

const Skill = () => {
  const data = useStaticQuery(graphql`
  query StackSkillQuery {
    allSkillJson {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`)

  return (
    <SkillList nodes={ data.allSkillJson.edges.map(e => e.node) }></SkillList>
)}

export default Skill;