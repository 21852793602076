import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class Counter extends React.PureComponent {
  componentDidMount() {
    const isSSR = typeof window === 'undefined'

    if (!isSSR) {
      import('counterup2').then(counter2 =>
        import('waypoints/lib/noframework.waypoints').then(x => {
          // eslint-disable-next-line react/no-find-dom-node
          const node = ReactDOM.findDOMNode(this)

          if (!(node instanceof HTMLElement)) return
          const el = node.querySelector('.counter')
          // eslint-disable-next-line no-new
          new global.Waypoint({
            element: el,
            handler: function () {
              counter2.default(el, {
                duration: 4000,
                delay: 16
              })
              this.destroy()
            },
            offset: 'bottom-in-view'
          })
        }) // import
      )
    }
  }

  render() {
    const { node } = this.props
    return (
      <div key={'counter_' + node.name.replace(' ', '')} className="col-lg-3 col-md-6 col-xs-12">
        <div className="counter-box wow fadeInUp" data-wow-delay={node.delay}>
          <div className="icon-o"><i className={node.icon}></i></div>
          <div className="fact-count">
            <h3><span className="counter">{node.value}</span></h3>
            <p>{node.name}</p>
          </div>
        </div>
      </div>)
  }
}

Counter.propTypes = {
  node: PropTypes.any
}

export default Counter
