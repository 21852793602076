import React from 'react'

import PropTypes from 'prop-types'

import Team from './Team'

class TeamList extends React.PureComponent {
  render() {
    const { nodes } = this.props

    return (
      <section id="team" className="section-padding text-center">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Meet our team</h2>
            <p>A desire to help and empower others between community contributors in technology <br /> began to grow in 2020.</p>
          </div>
          <div className="row">
            {nodes.map((e, i) => <Team key={`eteam_${i}`} node={e}></Team>)}
          </div>
        </div>
      </section>)
  }
}

TeamList.propTypes = {
  nodes: PropTypes.any
}

export default TeamList
