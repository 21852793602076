import React from 'react'
import PropTypes from 'prop-types'

class Client extends React.PureComponent {
  render() {
    const { node } = this.props

    return (
      <div key={'client_' + node.name.replace(' ', '')} className="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.3s">
        <div className="client-item-wrapper">
          <img className="img-fluid" src="/stack/img/clients/img1.png" alt="" />
        </div>
      </div>)
  }
}

Client.propTypes = {
  node: PropTypes.any
}

export default Client
