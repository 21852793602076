import React from 'react'
import PropTypes from 'prop-types'

import Blog from './Blog'

class BlogList extends React.PureComponent {
  render() {
    var { nodes } = this.props

    return (
      <section id="blog" className="section-padding">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Latest blog</h2>
            <p>A desire to help and empower others between community contributors in technology <br /> began to grow in 2020.</p>
          </div>
          <div className="row">
            {nodes.map((e, i) => <Blog key={'eblog_' + i} node={e}></Blog>)}
          </div>
        </div>
      </section>)
  }
}

BlogList.propTypes = {
  nodes: PropTypes.any
}

export default BlogList
