import React from 'react'
import PropTypes from 'prop-types'

class Product extends React.PureComponent {
  render () {
    const { node } = this.props

    return <div key={'product_' + node.name.replace(' ', '')} className="col-md-6 col-lg-4 col-xs-12">
      <div className="services-item wow fadeInRight" data-wow-delay="0.3s">
        <div className="icon">
          <i className={node.icon}></i>
        </div>
        <div className="services-content">
          <h3><a key={'prod_name' + node.name.replace(' ', '')}>{node.name}</a></h3>
          <p>{node.name}</p>
        </div>
        <div>
          {node.actions.map(action => <a key={'pa' + node.name + action.url} href={action.url} className="btn btn-common">{action.title}</a>)}
        </div>
      </div>
    </div>
  }
}

Product.propTypes = {
  node: PropTypes.any
}

export default Product
