import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import TestimonyList from './components/TestimonyList'

const Testimony = () => {
  const data = useStaticQuery(graphql`
  query StackTestimonyQuery {
    allTestimonyJson {
      edges {
        node {
          name
          description
          position
          organization
          content
          image
        }
      }
    }
  }
`)

  return (
    <TestimonyList nodes={ data.allTestimonyJson.edges.map(e => e.node) }></TestimonyList>
  )
}

export default Testimony
