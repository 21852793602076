import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import PortfolioList from './components/PortfolioList'

const Portfolio = () => {
  const data = useStaticQuery(graphql`
  query StackPortfolioQuery {
    allPortfolioJson {
      edges {
        node {
          name,
          image,
          category
        }
      }
    }
  }
`)

  var nodes = data.allPortfolioJson.edges.map(e => e.node)

  return (<PortfolioList nodes={nodes}></PortfolioList>)
}

export default Portfolio
