import React from 'react';
import { useStaticQuery, graphql } from "gatsby";

import PricingTable from './components/PricingTable';

const Pricing = () => {
  const data = useStaticQuery(graphql`
  query StackPricingQuery {
    allPricingJson {
      edges {
        node {
          name,
          description,
          visible,
          active,
          price,
          delay,
          items {
            name
          }
        }
      }
    }
  }
  `)
 
  return (

    <PricingTable nodes={ data.allPricingJson.edges.map(e => e.node) }></PricingTable>
)
  }

export default Pricing;