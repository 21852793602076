import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import CounterList from './components/CounterList'

const Counter = () => {
  const data = useStaticQuery(graphql`
  query StackCounterQuery {
    allCounterJson {
      edges {
        node {
          name
          description
          value
          delay
        }
      }
    }
  }
`)

  return (
    <CounterList nodes={data.allCounterJson.edges.map(e => e.node)}></CounterList>
  )
}

export default Counter
