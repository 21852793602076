import React from 'react'

import PropTypes from 'prop-types'
import Service from './Service'

class ServiceList extends React.PureComponent {
  render() {
    const { nodes } = this.props

    return (
      <section id="services" className="section-padding bg-gray">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Our Services</h2>
            <p>A desire to help and empower others between community contributors in technology <br/> began to grow in 2020.</p>
          </div>
          <div className="row">
            { nodes.map((e, i) => <Service key={`eservice_${i}`} node={e}></Service>)}
          </div>
        </div>
      </section>
    )
  }
}

ServiceList.propTypes = {
  nodes: PropTypes.any
}

export default ServiceList
