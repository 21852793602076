import React from 'react'

import PropTypes from 'prop-types'

class Service extends React.PureComponent {
  render() {
    const { node } = this.props

    return <div key={'service_' + node.title} className="col-md-6 col-lg-4 col-xs-12">
      <div className="services-item wow fadeInRight" data-wow-delay="0.3s">
        <div className="icon">
          <i className={node.icon}></i>
        </div>
        <div className="services-content">
          <h3><a>{node.title}</a></h3>
          <p>{node.content}</p>
        </div>
      </div>
    </div>
  }
}
Service.propTypes = {
  node: PropTypes.any
}

export default Service
