import React from 'react'
import PropTypes from 'prop-types'
import Client from './Client'

class ClientList extends React.PureComponent {
  render() {
    const { nodes } = this.props
    return (
      <div id="clients" className="section-padding bg-gray">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">NOTABLE CLIENTS</h2>
            <p>Over the last 20 years, we have helped and guided organisations to achieve outstanding results</p>
          </div>
          <div className="row text-align-">
            {nodes.map((e, i) => <Client key={'eclient_' + i} node={e} />)}
          </div>
        </div>
      </div>
    )
  }
}

ClientList.propTypes = {
  nodes: PropTypes.any
}

export default ClientList
