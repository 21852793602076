import React from 'react'
import Counter from './Counter'

import PropTypes from 'prop-types'

class CounterList extends React.PureComponent {
  render() {
    const { nodes } = this.props
    return (
      <section id="counter" className="section-padding">
        <div className="overlay"></div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 col-md-12 col-xs-12">
              <div className="row">
                { nodes.map((e, i) => <Counter key={'ecounter_' + i} node={e}></Counter>) }
              </div>
            </div>
          </div>
        </div>
      </section>)
  }
}

CounterList.propTypes = {
  nodes: PropTypes.any
}

export default CounterList
