import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import $ from 'jquery'

import Portfolio from './Portfolio'

import '../styles/nivo-lightbox.css'

class PortfolioList extends React.PureComponent {
  componentDidMount() {
    const isSSR = typeof window === 'undefined'

    if (!isSSR) {
      import('mixitup').then(mixitup => import('../nivo-lightbox.js')
        .then(x => {
          console.log('mixitup')
          /*
          mixitup('#portfolio', {
            selectors: {
              // control: '[data-mixitup-control]'
            },
            animation: {
              duration: 200
            }
          }) */
          $('.lightbox').nivoLightbox({
            effect: 'fadeScale',
            keyboardNav: true
          })
        })
      )
    }
  }

  render() {
    const { nodes } = this.props

    return (
      <section id="portfolios" className="section-padding">
        { /* Container Starts */}
        <div className="container">
          <div className="section-header text-center">
            <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Our Works</h2>
            <p>A desire to help and empower others between community contributors in technology <br /> began to grow in 2020.</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              { /* Portfolio Controller/Buttons */}
              <div className="controls text-center">
                <a className="filter active btn btn-common btn-effect" data-filter="all">
                  All
                </a>
                <a className="filter btn btn-common btn-effect" data-filter=".design">
                  Design
                </a>
                <a className="filter btn btn-common btn-effect" data-filter=".development">
                  Development
                </a>
                <a className="filter btn btn-common btn-effect" data-filter=".print">
                  Print
                </a>
              </div>
              { /* Portfolio Controller/Buttons Ends */}
            </div>
          </div>
          <div id="portfolio" className="row">
            {nodes.map((e, i) => <Portfolio key={'eportfolio_' + i} node={e}></Portfolio>)}
          </div>
        </div>
        { /* Container Ends */}
      </section>
    )
  }
}

PortfolioList.propTypes = {
  nodes: PropTypes.any
}

export default PortfolioList
